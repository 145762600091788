import Vuesax from "vuesax-ts";

export default defineNuxtPlugin(nuxtApp => {
  const { vueApp } = nuxtApp;
  vueApp.use(Vuesax, {
    colors: {
      primary: "15, 61, 90",
      success: "35, 165, 90",
      danger: "237, 66, 69",
      warning: "rgb(255, 130, 0)",
      dark: "0, 0, 0"
    }
  });
});
