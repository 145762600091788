import mitt from "mitt";

export default defineNuxtPlugin(() => {
  const emitter = mitt();

  return {
    provide: {
      events: {
        emit: emitter.emit, // Will emit an event
        on: emitter.on, // Will register a listener for an event
        once: emitter.once,// Will register a listener for an event that will be called only once,
        off: emitter.off // Will remove a listener for an event
      }
    }
  };
});
