// plugins/socket.client.ts
import { io } from "socket.io-client";

let socket;

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  if (!socket) socket = io(config.public.apiBase, {
    reconnection: true,
    reconnectionDelay: 3000
  });

  return {
    provide: {
      socket
    }
  };
});
