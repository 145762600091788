// Import styles
import "../assets/scss/default.scss";

const text2Price = (text, minimumDigits = 0) => {
  return Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: minimumDigits
  }).format(text);
};
export { text2Price };

const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "Ko", "Mo", "Go", "To"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
};
export { bytesToSize };

const stats2data = (stats, deleteAfterNow) => {
  let currentYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let lastYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const currentYearStr = new Date().getFullYear().toString();
  const lastYearStr = (new Date().getFullYear() - 1).toString();
  for (const [monthYear, count] of Object.entries(stats.months)) {
    console.log(monthYear, monthYear.split("/"));
    const [month, year] = monthYear.split("/");
    if (year === currentYearStr) {
      currentYear[month - 1] = count;
    } else if (year === lastYearStr) {
      lastYear[month - 1] = count;
    }
  }
  if (deleteAfterNow) {
    const currentMonth = new Date().getMonth();
    currentYear = currentYear.slice(0, currentMonth);
    lastYear = lastYear.slice(0, currentMonth);
  }
  return [currentYear, lastYear];
};
export { stats2data };

/**
 * mm2px - Convert millimeters to pixels
 * @param mm - Millimeters
 * @returns {number} Pixels
 */
const mm2px = (mm) => {
  return mm * 3.7795275590551;
};
export { mm2px };

/**
 * deg2rad - Convert degrees to radians
 * @param {number} deg - Degrees
 * @return {number} Radians
 */
const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};
export { deg2rad };

/**
 * rad2deg - Convert radians to degrees
 * @param {number} rad - Radians
 * @return {number} Degrees
 */
const rad2deg = (rad) => {
  return rad * (180 / Math.PI);
};
export { rad2deg };

const raw2base64 = (raw) => {
  return btoa(new Uint8Array(raw).reduce((data, byte) => data + String.fromCharCode(byte), ""));
};
export { raw2base64 };
