const LOGGING = false;

let user = reactive({});
let account = reactive({
  balance: 0,
  displayname: "",
  email: "",
  id: "",
  minimal_balance: 0,
  minimal_credit: 0,
  subscription_plan: null
});

export default defineNuxtRouteMiddleware(async (to, from) => {
  LOGGING && console.log("--------------------");
  if (to.meta?.auth === false) {
    LOGGING && console.log(`${ from.path } -> ${ to.path } is not protected by auth`);
    return true;
  } else LOGGING && console.log(`[ROUTER] ${ from.path } -> ${ to.path }`);
  const auth = useAuth(); // Utilise ton composable useAuth
  // Attendre la vérification de l'état d'authentification
  await auth.refreshAuthStatus();
  const authStatus = auth.getAuthStatus();

  LOGGING && console.log("[AUTH] Auth status:", authStatus.status);

  if (authStatus.status === "unauthenticated" && to.path !== "/login") {
    // Si l'utilisateur n'est pas authentifié et n'est pas sur la page de connexion,
    // rediriger vers la page de connexion
    setPageLayout("clean");
    return navigateTo("/login");
  }

  if (to.path?.startsWith("/management") && !authStatus.data?.user?.isAdmin) {
    setPageLayout("default");
    return navigateTo("/");
  } else if (to.path?.startsWith("/management")) setPageLayout("management");

  const nuxtApp = useNuxtApp();
  if (authStatus.data?.printer) {
    if (!nuxtApp.$printer) nuxtApp.provide("printer", authStatus.data?.printer);
    setPageLayout("printers");
    if (!to.path?.startsWith("/printers")) {
      return navigateTo("/printers");
    }
  } else {
    account.balance = authStatus.data?.account?.balance;
    account.displayname = authStatus.data?.account?.displayname;
    account.email = authStatus.data?.account?.email;
    account.id = authStatus.data?.account?.id;
    account.minimal_balance = authStatus.data?.account?.minimal_balance;
    account.minimal_credit = authStatus.data?.account?.minimal_credit;
    account.subscription_plan = authStatus.data?.account?.subscription_plan;
    account.options = authStatus.data?.account?.options;
  }
  if (!nuxtApp.$account && !authStatus.data?.printer) {
    nuxtApp.provide("account", account);
    watch(() => authStatus.data, (newVal, oldVal) => {
      account.balance = newVal.account?.balance;
      account.displayname = newVal.account?.displayname;
      account.email = newVal.account?.email;
      account.id = newVal.account?.id;
      account.minimal_balance = newVal.account?.minimal_balance;
      account.minimal_credit = newVal.account?.minimal_credit;
      account.subscription_plan = newVal.account?.subscription_plan;
    });
  }

  if (!authStatus.data?.printer) {
    if (account && !account.subscription_plan && to.path !== "/subscriptions") {
      return navigateTo("/subscriptions");
    }
  }

  if (!nuxtApp.$user) nuxtApp.provide("user", authStatus.data?.user);

  const _hsq = window._hsq = window._hsq || [];
  _hsq.push(["setPath"], to.path);
  _hsq.push(["trackPageView"]);

  _hsq.push(["identify", {
    email: nuxtApp.$user.email,
    id: nuxtApp.$user.id
  }]);

  return true;
});
